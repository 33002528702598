import React, { useState } from 'react'
import { Link, Outlet, useLocation } from 'react-router-dom'
import { FiMenu, FiX, FiSettings } from 'react-icons/fi' // Import the new icons
import { BiHome, BiMessageSquareAdd, BiListOl } from 'react-icons/bi'
import { HiOutlineLogout } from 'react-icons/hi'
import { FaListAlt, FaBlog, FaRegImages } from 'react-icons/fa'
import { GrGallery, GrUpdate } from 'react-icons/gr'
import { ImBlog } from 'react-icons/im'
import { useMediaQuery } from 'react-responsive'
import { useEffect } from 'react'
import LogOut from '../../action/logout'

function Layout() {
  const isMobile = useMediaQuery({ maxWidth: 950 })
  const [isSidebarExpanded, setSidebarExpanded] = useState(true)
  const { pathname } = useLocation()
  // console.log(pathname)
  useEffect(() => {
    if (isMobile) {
      setSidebarExpanded(false)
    } else {
      setSidebarExpanded(true)
    }
  }, [isMobile, setSidebarExpanded])

  const toggleSidebar = () => {
    setSidebarExpanded(!isSidebarExpanded)
  }

  return (
    <div className={`flex h-screen overflow-hidden ${isSidebarExpanded ? 'sidebar-expanded' : 'sidebar-collapsed'}`}>
      <aside
        className={`flex flex-col bg-gray-800 text-gray-300 transition-all duration-300 ease-in-out ${
          isSidebarExpanded ? 'w-64' : 'w-20'
        }`}
      >
        <div className="h-20 flex items-center px-4 bg-gray-900 hover:text-gray-100 hover:bg-opacity-50 focus:outline-none focus:text-gray-100 focus:bg-opacity-50 overflow-hidden">
          <button
            className={`flex items-center h-10 px-3 text-white ${
              isSidebarExpanded ? 'bg-red-600' : 'bg-green-600'
            } rounded-lg transition-colors duration-150 ease-in-out focus:outline-none focus:shadow-outline`}
            onClick={toggleSidebar}
          >
            {isSidebarExpanded ? <FiX size={24} /> : <FiMenu size={24} />}
          </button>
          <span className={`ml-2 text-xl font-medium ${isSidebarExpanded ? 'block' : 'hidden'}`}>Dashboard</span>
        </div>
        <nav className="p-4 space-y-2 font-medium">
          <Link
            to="/"
            className={`flex items-center h-10 px-3 ${
              pathname === '/' ? 'bg-blue-600 text-white' : 'hover:bg-blue-600 hover:bg-opacity-25'
            } rounded-lg transition-colors duration-150 ease-in-out focus:outline-none focus:shadow-outline`}
          >
            <div className="flex items-center">
              <BiHome className="h-6 w-6" />
              <span className={`ml-2 ${isSidebarExpanded ? 'block' : 'hidden'}`}>Home</span>
            </div>
          </Link>
          <Link
            to="/add-product"
            className={`flex items-center h-10 px-3 ${
              pathname === '/add-product' ? 'bg-blue-600 text-white' : 'hover:bg-blue-600 hover:bg-opacity-25'
            } rounded-lg transition-colors duration-150 ease-in-out focus:outline-none focus:shadow-outline`}
          >
            <div className="flex items-center">
              <BiMessageSquareAdd className="h-6 w-6" />
              <span className={`ml-2 ${isSidebarExpanded ? 'block' : 'hidden'}`}>Add Product</span>
            </div>
          </Link>
          <Link
            to="/products-list"
            className={`flex items-center h-10 px-3 ${
              pathname === '/products-list' ? 'bg-blue-600 text-white' : 'hover:bg-blue-600 hover:bg-opacity-25'
            } rounded-lg transition-colors duration-150 ease-in-out focus:outline-none focus:shadow-outline`}
          >
            <div className="flex items-center">
              <BiListOl className="h-6 w-6" />
              <span className={`ml-2 ${isSidebarExpanded ? 'block' : 'hidden'}`}>Products List</span>
            </div>
          </Link>
          <Link
            to="/update-city-country"
            className={`flex items-center h-10 px-3 ${
              pathname === '/update-city-country' ? 'bg-blue-600 text-white' : 'hover:bg-blue-600 hover:bg-opacity-25'
            } rounded-lg transition-colors duration-150 ease-in-out focus:outline-none focus:shadow-outline`}
          >
            <div className="flex items-center">
              <GrUpdate className="h-6 w-6" />
              <span className={`ml-2 ${isSidebarExpanded ? 'block' : 'hidden'}`}>Update City Country</span>
            </div>
          </Link>
          <Link
            to="/data-list"
            className={`flex items-center h-10 px-3 ${
              pathname === '/data-list' ? 'bg-blue-600 text-white' : 'hover:bg-blue-600 hover:bg-opacity-25'
            } rounded-lg transition-colors duration-150 ease-in-out focus:outline-none focus:shadow-outline`}
          >
            <div className="flex items-center">
              <FaListAlt className="h-6 w-6" />
              <span className={`ml-2 ${isSidebarExpanded ? 'block' : 'hidden'}`}>Data List</span>
            </div>
          </Link>
          <Link
            to="/add-blog"
            className={`flex items-center h-10 px-3 ${
              pathname === '/add-blog' ? 'bg-blue-600 text-white' : 'hover:bg-blue-600 hover:bg-opacity-25'
            } rounded-lg transition-colors duration-150 ease-in-out focus:outline-none focus:shadow-outline`}
          >
            <div className="flex items-center">
              <ImBlog className="h-6 w-6" />
              <span className={`ml-2 ${isSidebarExpanded ? 'block' : 'hidden'}`}>Add Blog</span>
            </div>
          </Link>
          <Link
            to="/blog-list"
            className={`flex items-center h-10 px-3 ${
              pathname === '/blog-list' ? 'bg-blue-600 text-white' : 'hover:bg-blue-600 hover:bg-opacity-25'
            } rounded-lg transition-colors duration-150 ease-in-out focus:outline-none focus:shadow-outline`}
          >
            <div className="flex items-center">
              <FaBlog className="h-6 w-6" />
              <span className={`ml-2 ${isSidebarExpanded ? 'block' : 'hidden'}`}>Blog List</span>
            </div>
          </Link>
          <Link
            to="/gallery"
            className={`flex items-center h-10 px-3 ${
              pathname === '/gallery' ? 'bg-blue-600 text-white' : 'hover:bg-blue-600 hover:bg-opacity-25'
            } rounded-lg transition-colors duration-150 ease-in-out focus:outline-none focus:shadow-outline`}
          >
            <div className="flex items-center">
              <GrGallery className="h-6 w-6" />
              <span className={`ml-2 ${isSidebarExpanded ? 'block' : 'hidden'}`}>Gallery</span>
            </div>
          </Link>
          <Link
            to="/product-images"
            className={`flex items-center h-10 px-3 ${
              pathname === '/product-images' ? 'bg-blue-600 text-white' : 'hover:bg-blue-600 hover:bg-opacity-25'
            } rounded-lg transition-colors duration-150 ease-in-out focus:outline-none focus:shadow-outline`}
          >
            <div className="flex items-center">
              <FaRegImages className="h-6 w-6" />
              <span className={`ml-2 ${isSidebarExpanded ? 'block' : 'hidden'}`}>Product Images</span>
            </div>
          </Link>
        </nav>
        <div className="border-t border-gray-700 p-4 font-medium mt-auto">
          <Link
            to="/update-info"
            className="flex items-center h-10 px-3 hover:text-gray-100 hover:bg-gray-600 hover:bg-opacity-25 rounded-lg transition-colors duration-150 ease-in-out focus:outline-none focus:shadow-outline"
          >
            <div className="flex items-center">
              <FiSettings className="h-6 w-6 flex-grow " />
              <span className={`ml-2 ${isSidebarExpanded ? 'block' : 'hidden'}`}>Update Info</span>
            </div>
          </Link>
          <div className="flex cursor-pointer items-center h-10 px-3 hover:text-gray-100 hover:bg-gray-600 hover:bg-opacity-25 rounded-lg transition-colors duration-150 ease-in-out focus:outline-none focus:shadow-outline">
            <div className="flex-grow flex items-center" onClick={() => LogOut()}>
              <HiOutlineLogout className="h-6 w-6" />
              <span className={`ml-2 ${isSidebarExpanded ? 'block' : 'hidden'}`}>Logout</span>
            </div>
          </div>
        </div>
        {/* Rest of your sidebar content */}
      </aside>

      <main className="flex-1 p-6 overflow-y-auto">
        {/* Outlet for route content */}
        <Outlet />
      </main>
    </div>
  )
}

export default Layout
