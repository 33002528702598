import React, { useState } from 'react'
import TextEditor from '../components/TextEditor/TextEditor'
import createAxiosInstance from './../action/apiConfig'
import LogOut from '../action/logout'
import FileUpload from '../components/UploadImage/UploadImage'
import { useNavigate } from 'react-router-dom'
import LocationAdd from '../components/LocationAdd/LocationAdd'
import FloorPlan from '../components/FloorPlan/FloorPlan'
import { CITIES, COUNTRIES, STATES } from '../lib/constants'

function AddProduct() {
  const [isPriceHave, setIsPriceHave] = useState(true)
  const [formData, setFormData] = useState({
    name: '',
    price: '',
    location: '',
    city: 'Select City',
    state: 'Select State',
    country: 'Select Country',
    coordinates: {
      coordinates: [0, 0]
    },
    type: '',
    tags: '',
    coverImage: '',
    images: [], // Initialize images as an empty array
    floorPlan: [],
    video: '',
    seoTitle: '',
    seoDes: '',
    seoKeywords: '',
    description: '',
    slug: '',
    featured: false
  })
  const [loading, setLoading] = useState(false) // Add loading state
  const [successMessage, setSuccessMessage] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [isValidSlug, setIsValidSlug] = useState(false)
  const [slugLoading, setSlugLoading] = useState(false)
  const navigate = useNavigate()
  const resetForm = () => {
    setFormData({
      name: '',
      price: '',
      location: '',
      city: 'Select City',
      state: 'Select State',
      country: 'Select Country',
      coordinates: {
        coordinates: [0, 0]
      },
      type: '',
      tags: '',
      coverImage: '',
      images: [], // Initialize images as an empty array
      floorPlan: [],
      video: '',
      seoTitle: '',
      seoDes: '',
      seoKeywords: '',
      description: '',
      slug: '',
      featured: false
    })
    setIsPriceHave(true)
  }
  const handleInputChange = (event) => {
    const { name, files } = event.target
    let newValue
    if (files) {
    } else {
      if (name === 'slug') {
        newValue = event.target.value.trim()
      } else {
        newValue = event.target.value
      }
    }
    setFormData({ ...formData, [name]: newValue })
    console.log(formData)
  }
  const axiosInstance = createAxiosInstance()
  const handleSubmit = async (event) => {
    event.preventDefault()
    if (isValidSlug !== 0) {
      alert('This slug already exists!')
      return
    }
    try {
      setLoading(true) // Set loading state to true

      // Make the API request to add the product using the Axios instance

      const response = await axiosInstance.post('/products/add', formData)
      resetForm()
      setSuccessMessage(response.data.message)
      setLoading(false) // Set loading state to false
      navigate('/products-list')
    } catch (error) {
      setErrorMessage(error.response.data.message)
      if (error.response.data.message === 'Unauthorized') {
        LogOut()
        console.log(`you have to logout`)
      }
      setLoading(false) // Set loading state to false

      // Display an error message here
    }
  }
  const checkSlug = (e) => {
    const enteredSlug = e.target.value
    if (enteredSlug === '') {
      setIsValidSlug(false)
      return
    }
    setSlugLoading(true)
    axiosInstance.post('/products/slugCheck', { slug: enteredSlug }).then((response) => {
      console.log(response.data)
      setIsValidSlug(response.data.count)
      setSlugLoading(false)
    })
  }

  return (
    <div className="w-full h-full mb-[320px]">
      <form action="" className="w-full h-full" onSubmit={handleSubmit}>
        <h1 className="text-4xl mb-5 font-bold">Add Product</h1>
        <h3 className="text-blue-900 font-semibold text-2xl text-center">Product Basic Details </h3>
        <div className="grid grid-cols-1 gap-5 my-5 lg:grid-cols-12">
          <div className="w-full lg:col-span-7">
            <label htmlFor="" className="block">
              Name <span className="text-red-600">*</span>
            </label>
            <input
              type="text"
              placeholder="Enter the product name"
              className="block w-full form-input rounded-md border-gray-500"
              onChange={handleInputChange}
              name="name"
              value={formData.name}
              autoComplete="off"
              required
            />
          </div>
          <div className="w-full lg:col-span-4">
            <label htmlFor="" className="block">
              Price <span className="text-red-600">*</span>
            </label>
            <div className="grid grid-cols-[160px,auto] gap-2 items-center">
              <div className="flex gap-1  h-[39px] items-center pt-[15px]">
                <label className="relative inline-flex items-center mb-4 cursor-pointer">
                  <input
                    type="checkbox"
                    defaultChecked={!isPriceHave}
                    onClick={() => {
                      setIsPriceHave(!isPriceHave)
                      setFormData({ ...formData, price: 'coming soon' })
                    }}
                    className="sr-only peer"
                  />
                  <div className="w-11 h-6 rounded-full peer peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                  <span className="ml-3 text-sm font-medium">Coming Soon..</span>
                </label>
              </div>
              <div className="relative">
                <div className="absolute inset-y-0 left-0 flex items-center pl-3.5 pointer-events-none">$</div>
                <input
                  type="text"
                  className="border-gray-500 form-input rounded-md block w-full pl-10 disabled:opacity-40"
                  placeholder="Enter the product price"
                  disabled={!isPriceHave}
                  name="price"
                  onChange={handleInputChange}
                  value={formData.price}
                  autoComplete="off"
                  required
                />
              </div>
            </div>
          </div>
          <div className="w-full">
            <label htmlFor="" className="block">
              Featured
            </label>
            <div className="grid grid-cols-[160px,auto] gap-2 items-center">
              <div className="flex gap-1  h-[39px] items-center pt-[15px]">
                <label className="relative inline-flex items-center mb-4 cursor-pointer">
                  <input
                    type="checkbox"
                    defaultChecked={formData.featured}
                    onClick={() => {
                      setFormData((st) => {
                        return { ...formData, featured: !st.featured }
                      })
                    }}
                    className="sr-only peer"
                  />
                  <div className="w-11 h-6 rounded-full peer peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                </label>
              </div>
            </div>
          </div>
        </div>

        {/* <div className="w-full my-5">
          <label htmlFor="" className="block">
            Location <span className="text-red-600">*</span>
          </label>
          <input
            type="text"
            name="location"
            className="block w-full form-input rounded-md border-gray-500"
            autoComplete="off"
            placeholder="Enter the product location"
            onChange={handleInputChange}
            value={formData.location}
            required
          />
        </div> */}
        <div className="w-full my-5">
          <label htmlFor="" className="block">
            Property Type <span className="text-red-600">*</span>
          </label>
          <input
            type="text"
            name="type"
            className="block w-full form-input rounded-md border-gray-500"
            autoComplete="off"
            placeholder="Enter the property type"
            onChange={handleInputChange}
            value={formData.type}
            required
          />
        </div>
        <div className="w-full my-5">
          <label className="pr-2" htmlFor="country">
            Choose a Country:
          </label>
          <select className="ml-2 w-48" name="country" id="country" onChange={handleInputChange}>
            <option value="Select Country">Select Country</option>
            {COUNTRIES.map((m) => (
              <option value={m} key={m} selected={m === formData.country ? 'selected' : ''}>
                {m}
              </option>
            ))}
          </select>
        </div>
        <div className="w-full my-5">
          <label className="pr-7" htmlFor="state">
            Choose a State:
          </label>
          <select className="ml-2 w-48" name="state" id="state" onChange={handleInputChange}>
            <option value="Select State">Select State</option>
            {STATES[formData.country]?.map((m) => (
              <option value={m} key={m} selected={m === formData.state ? 'selected' : ''}>
                {m}
              </option>
            ))}
          </select>
        </div>
        <div className="w-full my-5">
          <label className="pr-[38px]" htmlFor="city">
            Choose a City:
          </label>
          <select className="ml-2 w-48" name="city" id="city" onChange={handleInputChange}>
            <option value="Select City">Select City</option>
            {CITIES[formData.state]?.map((m) => (
              <option value={m} key={m} selected={m === formData.city ? 'selected' : ''}>
                {m}
              </option>
            ))}
          </select>
        </div>
        <div className="w-full my-5">
          <label htmlFor="" className="block">
            Slug <span className="text-red-600">*</span>
          </label>
          <input
            type="text"
            name="slug"
            className={`block w-full rounded-md border-gray-500 outline-none focus:border-green-600 ${
              isValidSlug === 0 ? 'border-green-600 focus:border-green-600' : ''
            } ${isValidSlug > 0 ? 'border-red-600 focus:border-red-600' : ''}`}
            autoComplete="off"
            placeholder="Enter the page slug"
            onChange={handleInputChange}
            value={formData.slug}
            onKeyUp={(e) => {
              setTimeout(() => {
                checkSlug(e)
              }, 1000)
            }}
            required
          />
          <div className="flex gap-2">
            {slugLoading && (
              <div role="status">
                <svg
                  aria-hidden="true"
                  class="inline w-4 h-4 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                  viewBox="0 0 100 101"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                    fill="currentColor"
                  />
                  <path
                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                    fill="currentFill"
                  />
                </svg>
                <span class="sr-only">Loading...</span>
              </div>
            )}
            {isValidSlug === 0 ? <p className="text-green-600">Slug is available to use!</p> : ''}
            {isValidSlug > 0 ? <p className="text-red-600">This slug already exists!</p> : ''}
          </div>
        </div>
        <LocationAdd formData={formData} setFormData={setFormData} />

        <div className="w-full my-5">
          <label htmlFor="" className="block">
            Tags <span className="text-red-600">*</span> <span className="text-green-600">(Comma Separated)</span>
          </label>
          <input
            type="text"
            name="tags"
            className="block w-full form-input rounded-md border-gray-500"
            autoComplete="off"
            placeholder="Enter tags here, separated by commas"
            onChange={handleInputChange}
            value={formData.tags}
            required
          />
        </div>
        {/* <div className="w-full my-5">
          <label htmlFor="" className="block">
            Product Cover Image <span className="text-red-600">*</span>
          </label>
          <input
            type="file"
            name="coverImage"
            className="block w-full form-input rounded-md border-gray-500"
            autoComplete="off"
            placeholder="Enter keywords here, separated by commas"
            accept="image/*"
            onChange={handleInputChange}
            required
            // value={formData.coverImage}
          />
        </div>
        <div className="w-full my-5">
          <label htmlFor="" className="block">
            Multiple Images for image gallery of the product
          </label>
          <input
            type="file"
            name="images"
            className="block w-full form-input rounded-md border-gray-500"
            autoComplete="off"
            placeholder="Enter keywords here, separated by commas"
            accept="image/*"
            multiple={true}
            onChange={handleInputChange}
          />
        </div> */}
        <FileUpload setFormData={setFormData} takenData={formData} />
        <div className="w-full my-5">
          <label htmlFor="" className="block">
            Video URL<span className="text-red-600"></span>
          </label>
          <input
            type="text"
            name="video"
            className="block w-full form-input rounded-md border-gray-500"
            autoComplete="off"
            placeholder="Enter video URL"
            onChange={handleInputChange}
            value={formData.video}
          />
        </div>
        <h3 className="text-blue-900 font-semibold text-2xl text-center block">Product SEO</h3>
        <div className="w-full my-5">
          <label htmlFor="" className="block">
            SEO Title <span className="text-red-600">*</span>
          </label>
          <input
            type="text"
            name="seoTitle"
            className="block w-full form-input rounded-md border-gray-500"
            autoComplete="off"
            placeholder="Enter SEO title"
            multiple={true}
            onChange={handleInputChange}
            value={formData.seoTitle}
            required
          />
        </div>
        <div className="w-full my-5">
          <label htmlFor="" className="block">
            SEO Meta Description <span className="text-red-600">*</span>
          </label>
          <textarea
            type="text"
            name="seoDes"
            className="block w-full form-input rounded-md border-gray-500"
            autoComplete="off"
            placeholder="Enter SEO meta description"
            accept="image/*"
            multiple={true}
            onChange={handleInputChange}
            value={formData.seoDes}
            required
          />
        </div>
        <div className="w-full my-5">
          <label htmlFor="" className="block">
            SEO Meta Keywords <span className="text-red-600">*</span>
            <span className="text-green-600">(Comma Separated)</span>
          </label>
          <textarea
            type="text"
            name="seoKeywords"
            className="block w-full form-input rounded-md border-gray-500"
            autoComplete="off"
            placeholder="Enter SEO meta keywords"
            multiple={true}
            onChange={handleInputChange}
            value={formData.seoKeywords}
            required
          />
        </div>
        <FloorPlan formData={formData} setFormData={setFormData} />

        <h3 className="text-blue-900 font-semibold text-2xl text-center block">Long Description</h3>
        <TextEditor setData={setFormData} data={formData} />
        {successMessage && <div className="text-green-600">{successMessage}</div>}
        {errorMessage && <div className="text-red-600">{errorMessage}</div>}
        <button
          type="submit"
          className="bg-blue-500 hover:bg-blue-700 px-4 flex w-full py-5 text-center rounded-md justify-center uppercase font-bold text-white my-10"
          disabled={loading} // Disable the button when loading
        >
          {loading ? 'Adding...' : 'Add the data'}
        </button>
        <p className="block p-10"></p>
      </form>
    </div>
  )
}

export default AddProduct
